import request from '@/utils/request'

const usersApi = {
  // get users info
  users: '/api/v1/rest/users',
  signUser: '/api/v1/user',
  deleteByIdsUser: '/api/v1/rest/users/search/deleteByIds',
  findByUsernameUser: '/api/v1/rest/users/search/findByUsername',
  findByWxOpenIdUser: '/api/v1/rest/users/search/findByWxOpenId',
  notBroker: '/api/v1/rest/users/search/findNotBroker',
  nobrokerQuery: '/api/v1/user/queryNoBroker',
  updatePassword: '/api/v1/user/updatePassword',
  resetPassword: '/api/v1/user/resetPassword',
  findByRole: '/api/v1//user/findByRole',
  saveByBack: '/api/v1/user/saveByBack',
  findById: '/api/v1/user/findById'
}

export function users(param) {
  const url = request.requestFormat(usersApi.users, param)
  return request({
    url,
    method: 'get'
  })
}

export function addUsers(data) {
  return request({
    url: usersApi.users,
    method: 'post',
    data
  })
}

export function saveByBack(data) {
  return request({
    url: usersApi.saveByBack,
    method: 'post',
    data
  })
}

export function getUsers(param) {
  const url = request.requestFormat(usersApi.findById, param)
  return request({
    url,
    method: 'get'
  })
}

export function findByRole(id) {
  return request({
    url: usersApi.findByRole + `?roleId=${id}`,
    method: 'get'
  })
}

export function putUsers(id, data) {
  return request({
    url: usersApi.users + `/${id}`,
    method: 'get',
    data
  })
}

export function editUsers(id, data) {
  return request({
    url: usersApi.users + `/${id}`,
    method: 'patch',
    data
  })
}

export function delUsers(id) {
  return request({
    url: usersApi.users + `/${id}`,
    method: 'delete'
  })
}

export function getUsersRoles(id) {
  return request({
    url: usersApi.users + `/${id}` + '/roles',
    method: 'get'
  })
}

export function putUsersRoles(id, data) {
  return request({
    url: usersApi.users + `/${id}` + '/roles',
    method: 'put',
    headers: {
      'Content-Type': 'text/uri-list'
    },
    data
  })
}

export function patchUsersRoles(id, data) {
  return request({
    url: usersApi.users + `/${id}` + '/roles',
    method: 'patch',
    data
  })
}

export function getUsersIdRoles(id, authId) {
  return request({
    url: usersApi.users + `/${id}` + '/roles' + `/${authId}`,
    method: 'get'
  })
}

export function delUsersIdRoles(id, authId) {
  return request({
    url: usersApi.users + `/${id}` + '/roles' + `/${authId}`,
    method: 'delete'
  })
}

export function delUsersRoles(id) {
  return request({
    url: usersApi.users + `/${id}` + '/roles',
    method: 'delete'
  })
}

export function getUsersTasks(id) {
  return request({
    url: usersApi.users + `/${id}` + '/tasks',
    method: 'get'
  })
}

export function putUsersTasks(id, data) {
  return request({
    url: usersApi.users + `/${id}` + '/tasks',
    method: 'get',
    headers: {
      'Content-Type': 'text/uri-list'
    },
    data
  })
}

export function patchUsersTasks(id, data) {
  return request({
    url: usersApi.users + `/${id}` + '/tasks',
    method: 'patch',
    data
  })
}

export function delUsersTasks(id) {
  return request({
    url: usersApi.users + `/${id}` + '/tasks',
    method: 'delete'
  })
}

export function getUsersIdTasks(id, authId) {
  return request({
    url: usersApi.users + `/${id}` + '/tasks' + `/${authId}`,
    method: 'get'
  })
}

export function delUsersIdTasks(id) {
  return request({
    url: usersApi.users + `/${id}` + '/tasks',
    method: 'delete'
  })
}

export function signUser(param) {
  const url = request.requestFormat(usersApi.signUser, param)
  return request({
    url,
    method: 'get'
  })
}

export function deleteByIdsUser(param) {
  const url = request.requestFormat(usersApi.deleteByIdsUser, param)
  return request({
    url,
    method: 'get'
  })
}

export function findByUsernameUser(param) {
  const url = request.requestFormat(usersApi.findByUsernameUser, param)
  return request({
    url,
    method: 'get'
  })
}

export function findByWxOpenIdUser(param) {
  const url = request.requestFormat(usersApi.findByWxOpenIdUser, param)
  return request({
    url,
    method: 'get'
  })
}

export function nobrokerQuery(param) {
  const url = request.requestFormat(usersApi.nobrokerQuery, param)
  return request({
    url: url,
    method: 'get'
  })
}

export function updatePassword(param) {
  const url = request.requestFormat(usersApi.updatePassword, param)
  return request({
    url,
    method: 'get'
  })
}

export function resetPassword(param) {
  const url = request.requestFormat(usersApi.resetPassword, param)
  return request({
    url,
    method: 'get'
  })
}
