import request from '@/utils/request'

const userApi = {
  // Login: '/auth/login',
  Login: '/api/v1/user/token',
  Logout: '/api/v1/user/logout',
  // get my info
  // UserInfo: '/user/info',
  UserInfo: '/api/v1/user/me',
  UserMenu: '/user/nav'
}

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */
export function login(parameter) {
  const data = new FormData();
  Object.keys(parameter).forEach(k=>{
    data.append(k, parameter[k]);
  })
  return request({
    url: userApi.Login,
    method: 'post',
    data
  })
}

export function getInfo() {
  return request({
    url: userApi.UserInfo,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function getCurrentUserNav() {
  return request({
    url: userApi.UserMenu,
    method: 'get'
  })
}

export function logout() {
  return request({
    url: userApi.Logout,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
