export function timeFix() {
  const time = new Date()
  const hour = time.getHours()
  return hour < 9 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 20 ? '下午好' : '晚上好'
}

export function welcome() {
  const arr = ['休息一会儿吧', '准备吃什么呢?', '要不要打一把 DOTA', '我猜你可能累了']
  const index = Math.floor(Math.random() * arr.length)
  return arr[index]
}

/**
 * 触发 window.resize
 */
export function triggerWindowResizeEvent() {
  const event = document.createEvent('HTMLEvents')
  event.initEvent('resize', true, true)
  event.eventType = 'message'
  window.dispatchEvent(event)
}

export function handleScrollHeader(callback) {
  let timer = 0

  let beforeScrollTop = window.pageYOffset
  callback = callback || function () { }
  window.addEventListener(
    'scroll',
    event => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        let direction = 'up'
        const afterScrollTop = window.pageYOffset
        const delta = afterScrollTop - beforeScrollTop
        if (delta === 0) {
          return false
        }
        direction = delta > 0 ? 'down' : 'up'
        callback(direction)
        beforeScrollTop = afterScrollTop
      }, 50)
    },
    false
  )
}

export function isIE() {
  const bw = window.navigator.userAgent
  const compare = (s) => bw.indexOf(s) >= 0
  const ie11 = (() => 'ActiveXObject' in window)()
  return compare('MSIE') || ie11
}

/**
 * Remove loading animate
 * @param id parent element id or class
 * @param timeout
 */
export function removeLoadingAnimate(id = '', timeout = 1500) {
  if (id === '') {
    return
  }
  setTimeout(() => {
    document.body.removeChild(document.getElementById(id))
  }, timeout)
}

/**
 * formt time 
 * @param arr Array
 * @param property 属性名
 */
export function getTime(arr, property) {
  arr.forEach(item => {
    const time = new Date(item[property])
    let key = ''
    const timeObj = {
      year: time.getFullYear(),
      month: time.getMonth() + 1,
      date: time.getDate(),
      hours: time.getHours(),
      minutes: time.getMinutes()
    }
    for (key in timeObj) {
      if (parseInt(timeObj[key]) < 10) {
        timeObj[key] = '0' + timeObj[key]
      }
    }
    item[property] = `${timeObj.year}/${timeObj.month}/${timeObj.date} ${timeObj.hours}:${timeObj.minutes}`
  })
}

export function getDate(date) {
  const time = new Date(date)
  let key = ''
  const timeObj = {
    year: time.getFullYear(),
    month: time.getMonth() + 1,
    date: time.getDate(),
    hours: time.getHours(),
    minutes: time.getMinutes(),
    seconds: time.getSeconds()
  }
  for (key in timeObj) {
    if (parseInt(timeObj[key]) < 10) {
      timeObj[key] = '0' + timeObj[key]
    }
  }
  return `${timeObj.year}-${timeObj.month}-${timeObj.date} ${timeObj.hours}:${timeObj.minutes}:${timeObj.seconds}`
}

/**
 * form data 
 * @param { id: 1, name: '张三' }
 */
export function setPayload(data) {
  const parameter = new URLSearchParams()
  let key = ''
  for (key in data) {
    parameter.append(key, data[key])
  }
  return parameter
}

export function dateFormat(time, format = 'YYYY-MM-DD hh:mm:ss') {
  const date = new Date(time)
  const timeObj = {
    year: date.getFullYear(),
    month: parseInt(date.getMonth()) + 1,
    date: date.getDate(),
    hours: date.getHours(),
    minutes: date.getMinutes(),
    seconds: date.getSeconds()
  }
  let key = ''
  for (key in timeObj) {
    if (key !== 'year') {
      timeObj[key] = parseInt(timeObj[key]) > 9 ? `${timeObj[key]}` : `0${timeObj[key]}`
    }
  }
  console.log(format);
  switch (format) {
    case 'hh:mm:ss':
      return `${timeObj.hours}:${timeObj.minutes}:${timeObj.seconds}`
    case 'YYYY-MM-DD':
      return `${timeObj.year}-${timeObj.month}-${timeObj.date}`
    case 'hh:mm':
      return `${timeObj.hours}:${timeObj.minutes}`
    case 'YYYY-MM-DD 00:00:00':
      return `${timeObj.year}-${parseInt(timeObj.month)}-${timeObj.date} 00:00:00`
    default:
      return `${timeObj.year}-${parseInt(timeObj.month)}-${timeObj.date} ${timeObj.hours}:${timeObj.minutes}:${timeObj.seconds}`
  }
}

export function getCity(arr) {
  if (arr.length !== 0) {
    arr.forEach((item, index) => {
      arr[index] = {
        value: item.name,
        label: item.name
      }
      if (item.children.length !== 0) {
        arr[index].children = item.children
        if (arr[index].children.length !== 0) {
          arr[index].children.forEach((childItem, childIndex) => {
            arr[index].children[childIndex] = {
              value: childItem.name,
              label: childItem.name
            }
            if (childItem.children.length !== 0) {
              arr[index].children[childIndex].children = childItem.children
              getCity(arr[index].children[childIndex].children)
            }
          })
        }
      }
    })
    return arr
  } else {
    return arr
  }
}

export function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split('&')
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    if (pair[0] === variable) { return pair[1]; }
  }
  return (false);
}
// 表单验证
export function phoneValidator(rules, value, callback) {
  const myReg = /^1[3456789]\d{9}$/
  if (value && !myReg.test(value)) {
    callback(new Error('请输入11位数字组成的手机号'))
  }
  callback()
}

export function userValidator(rules, value, callback) {
  const myreg = /^[A-Za-z0-9]+$/
  if (value && value.length < 5 || value && value.length > 12) {
    callback(new Error('用户名为英文和数字组成的5到12个字符'))
  } else if (value && !myreg.test(value)) {
    callback(new Error('请输入英文、数字'))
  }
  callback()
}

export function passwordValidator(rules, value, callback) {
  const myreg = /^[A-Za-z0-9]+$/
  if (value && value.length < 5 || value && value.length > 12) {
    callback(new Error('密码为英文和数字组成的5到12个字符'))
  } else if (value && !myreg.test(value)) {
    callback(new Error('请输入英文、数字'))
  }
  callback()
}

export function nameValidator(rules, value, callback) {
  const myreg = /^[\u4E00-\u9FA5A-Za-z0-9]+$/
  if (value && !myreg.test(value)) {
    callback(new Error('请输入中文、英文、数字'))
  }
  callback()
}

export function numberValidator(rules, value, callback) {
  const myreg = /^[0-9]*$/
  if (value && !myreg.test(value)) {
    callback(new Error('请输入数字'))
  }
  callback()
}

export function floatValidator(rules, value, callback) {
  const myreg = /^([1-9]\d*|0)(\.\d{1,2})?$/
  if (value && !myreg.test(value)) {
    callback(new Error('请输入最多带两位小数的数字'))
  }
  callback()
}

export function intNumberValidator(rules, value, callback) {
  const myreg = /^[1-9]\d*|0$/
  if (value && !myreg.test(value)) {
    callback(new Error('请输入非负整数'))
  }
  callback()
}

export function codeValidator(rules, value, callback) {
  const myreg = /^[A-Za-z0-9]+$/
  if (value && !myreg.test(value)) {
    callback(new Error('请输入数字和英文'))
  }
  callback()
}

export function phoneFormat(phone) {
    const arr = phone.toString().split('')
    arr.splice(3, 0, ' ')
    arr.splice(8, 0, ' ')
    return arr.join('')
}

export function priceFormat(price) {
  if (price) {
    price = price.toString()
    let isPositive = true
    let hasSuf = false
    let sufPrice = ''
    if (price.indexOf('-') > -1) isPositive = false
    if (!isPositive) price = price.slice(1)
    if (price.indexOf('.') > -1) {
      hasSuf = true
      const num = price.indexOf('.')
      sufPrice = price.slice(num, num + 3)
      price = price.slice(0, num)
    }
    const arr = price
      .toString()
      .split('')
      .reverse();
    let count = 0;
    if (arr.length % 3 === 0) {
      count = parseInt(arr.length / 3) - 1;
    } else {
      count = parseInt(arr.length / 3);
    }
    let i = 0;
    const num = 3;
    for (i; i < count; i++) {
      arr.splice(num * (i + 1) + i, 0, ',');
    }
    let str = arr.reverse().join('')
    if (!isPositive) str = '-' + str
    if (hasSuf) str += sufPrice
    return str;
  } else {
    if (price === 0) {
      return price
    } else {
      return '--'
    }
  }
}

export function preNumberFormat(number, type = '￥') {
  if (number) {
    return `${type}${priceFormat(number)}`
  } else {
    if (number === 0) {
      return number
    } else {
      return '--'
    }
  }
}

export function sufNumberFormat(number, type = '元') {
  if (number) {
    return `${priceFormat(number)} ${type}`
  } else {
    if (number === 0) {
      return number
    } else {
      return '--'
    }
  }
}
