export default {
  formDataFormat(params = {}) {
    const data = new FormData()
    let key = ''
    for (key in params) {
      data.append(key, params[key])
    }
    return data
  },
  dParamFormat(parameter, param = {}) {
    const pagination = {
        page: parameter.pageNo,
        page_size: parameter.pageSize
    }
    Object.assign(param, pagination)
    return param
  },
  dResFormat(res) {
    if (res.success) {
      const data = res.data
      const result = {
        data: data.data,
        pageNo: data.current_page - 1,
        pageSize: data.page_size,
        totalCount: data.count,
        totalPages: data.total_page
      }
      return result
    } else {
      const result = {
          data: [],
          pageNo: 0,
          pageSize: 10,
          totalCount: 0,
          totalPages: 0
      }
      this.notiError('获取数据', '获取失败')
      return result
    }
  },
  dResDataFormat(res) {
    if (res.success) {
      const data = res.data
      const len = data.length
      if (len === 0) {
        const result = {
          data: [],
          pageNo: 0,
          pageSize: 10,
          totalCount: 0,
          totalPages: 0
        }
        return result
      } else {
        // 处理 随机ID  
        if (!data[0].id) {
          data.forEach(i=>{
            i.id = Math.floor(Math.random() * 100000000)
          })
        }
        const result = {
          data,
          pageNo: 0,
          pageSize: 10,
          totalCount: len,
          totalPages: 0
        }
        return result
      }
    } else {
      const result = {
          data: [],
          pageNo: 0,
          pageSize: 10,
          totalCount: 0,
          totalPages: 0
      }
      this.notiError('获取数据', '获取失败')
      return result
    }
  }
}
