import { queryAddr } from '@/api/addrs'

const city = {
  state: {
    city: '',
    open: []
  },

  mutations: {
    SET_CITY: (state, city) => {
      state.city = city
    },
    SET_OPEN: (state, open) => {
      state.open = open
    }
  },

  actions: {
    City({ commit }) {
      return new Promise((resolve, reject) => {
        // 所有已开通城市
        const params = {
          level: 'province',
          page: 0,
          size: 1000
        }
        queryAddr(params).then(res => {
          const data = res.data.content || []
          // storage.set(APP_CITY, data)
          commit('SET_CITY', data)
          resolve(data)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}

export default city
