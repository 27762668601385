import Vue from 'vue'
import Vuex from 'vuex'

import app from './modules/app'
import user from './modules/user'
import dingUser from './modules/dingUser'
import oss from './modules/oss'
import env from './modules/env'

// default router permission control
import permission from './modules/permission'

// city
import city from './modules/city'

// dynamic router permission control (Experimental)
// import permission from './modules/async-router' // 暂时不使用异步的路由
import getters from './getters'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    user,
    dingUser,
    oss,
    env,
    permission,
    city
  },
  state: {

  },
  mutations: {

  },
  actions: {

  },
  getters
})
