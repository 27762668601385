import axios from 'axios'
// import router from '@/router'
// import store from '@/store'
import storage from 'store'
import notification from 'ant-design-vue/es/notification'
import { VueAxios } from './axios'
import { ACCESS_TOKEN } from '@/store/mutation-types'
// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  // baseURL: process.env.VUE_APP_API_BASE_URL, // test: process.env.VUE_APP_API_BASE_URL dev: 'http://11.0.2.150:8911/v1/'
  timeout: 1200000 // 请求超时时间
})

// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    const data = error.response.data
    // const status = error.response.status
    // 从 localstorage 获取 token
    // const token = storage.get(ACCESS_TOKEN)
    // if (error.response.status === 403) {
    //   notification.error({
    //     message: '无权访问',
    //     description: data.message
    //   })
    // }
    // if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
    //   notification.error({
    //     message: '授权失败',
    //     description: '授权验证失败，请重新登录。'
    //   })
    //   if (token) {
    //     storage.dispatch('Logout').then(() => {
    //       setTimeout(() => {
    //         notification.error({
    //           message: '授权失败',
    //           description: '授权验证失败，请重新登录。'
    //         })
    //         window.location.reload()
    //       }, 1500)
    //     })
    //   }
    // }

    // if (status === 503 || status === 500) {
    //   router.push({ path: '/500' })
    // }

    if (data.message.indexOf('用户或者密码错误') === -1) {
      if (!data.success) {
        // if (error.response.status === 500) {
        //   storage.remove(ACCESS_TOKEN)
        //   router.push({ path: '/500' })
        // }
        notification.error({
          message: '错误',
          description: data.message
        })
      }
    }
  }
  return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config => {
  const token = storage.get(ACCESS_TOKEN)
  // 如果 token 存在
  // 让每个请求携带自定义 token 请根据实际情况自行修改
  if (token) {
    config.headers['Access-Token'] = token
  }
  return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
  const data = response.data
  const success = data.success
  if (!success && success !== undefined) {
    notification.error({
      message: '错误',
      description: data.message
    })
  }
  return response.data
}, errorHandler)

request.requestFormat = (url, param) => {
  if (param) {
    url += '?' + Object.keys(param).map(item => `${item}=${param[item]}`).join('&')
  }
  return url
}

const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, request)
  }
}

export default request

export {
  installer as VueAxios,
  request as axios
}
