import request from '@/utils/request'

// const href = 'http://11.0.0.94:8080'
const href = '/api/v1/dingtalk'
const moxiApi = {
  // 部门
  department: href + '/sales/department',
  form: href + '/sales/form',
  // 实例
  instance: href + '/sales/instance',

  // 导出
  export: href + '/sales/instance/export',

  // 审批实例列表
  instances: href + '/sales/stat/instances',

  // 审批记录
  record: href + '/sales/record',

  // 统计相关
  stat: href + '/sales/stat',

  // 更新状态
  enforce_complete_status: href + '/sales/instance',

  // 用户
  user: href + '/sales/user',
  get_relation: href + '/sales/user/get_relation',
  relation: href + '/sales/user/relation'
}

// 删除单条数据
export function moxiDel(id, data) {
  const url = request.requestFormat(`${moxiApi.instance}/${data.process_instance_id}/`)
  return request({
    url,
    method: 'delete',
    headers: {
      Authorization: id
    }
  })
}

// 审批实例列表
export function moxiInstances(id, params) {
  const url = request.requestFormat(`${moxiApi.instances}/`, params)
  return request({
    url,
    method: 'get',
    headers: {
      Authorization: id
    }
  })
}

export function instanceGet(id, processId) {
  const url = `${moxiApi.instance}/${processId}/`
  return request({
    url,
    method: 'get',
    headers: {
      Authorization: id
    }
  })
}

export function moxiExport(id, params) {
  const url = request.requestFormat(`${moxiApi.export}/`, params)
  return request({
    url,
    method: 'get',
    headers: {
      Authorization: id
    }
  })
}

// 结佣相关
export function moxiCheckout(id, processId, data) {
  return request({
    url: moxiApi.instance + `/${processId}/checkout/`,
    method: 'post',
    headers: {
      Authorization: id
    },
    data
  })
}

export function moxiCheckouts(id, processId, params) {
  const url = request.requestFormat(moxiApi.instance + `/${processId}/checkouts/`, params)
  return request({
    url,
    method: 'get',
    headers: {
      Authorization: id
    }
  })
}

export function moxiCheckoutStatus(id, processId, data) {
  return request({
    url: moxiApi.instance + `/${processId}/enforce_complete_status/`,
    method: 'post',
    headers: {
      Authorization: id
    },
    data
  })
}

// 拆佣相关
export function moxiDistribute(id, processId, data) {
  return request({
    url: moxiApi.instance + `/${processId}/distribute/`,
    method: 'post',
    headers: {
      Authorization: id
    },
    data
  })
}

export function moxiDistributions(id, processId, params) {
  const url = request.requestFormat(moxiApi.instance + `/${processId}/distributions/`, params)
  return request({
    url,
    method: 'get',
    headers: {
      Authorization: id
    }
  })
}

export function moxiDistributeStatus(id, processId, data) {
  return request({
    url: moxiApi.instance + `/${processId}/enforce_complete_status/`,
    method: 'post',
    headers: {
      Authorization: id
    },
    data
  })
}

// 开票相关
export function moxiInvoice(id, processId, data) {
  return request({
    url: moxiApi.instance + `/${processId}/invoicing/`,
    method: 'post',
    headers: {
      Authorization: id
    },
    data
  })
}

export function moxiInvoices(id, processId, params) {
  console.log(id, 'invoice id');
  const url = request.requestFormat(moxiApi.instance + `/${processId}/invoices/`, params)
  return request({
    url,
    method: 'get',
    headers: {
      Authorization: id
    }
  })
}

export function moxiInvoiceStatus(id, processId, data) {
  return request({
    url: moxiApi.instance + `/${processId}/enforce_complete_status/`,
    method: 'post',
    headers: {
      Authorization: id
    },
    data
  })
}

// 回款相关
export function moxiPayback(id, processId, data) {
  return request({
    url: moxiApi.instance + `/${processId}/pay_back/`,
    method: 'post',
    headers: {
      Authorization: id
    },
    data
  })
}

export function moxiPaybacks(id, processId, params) {
  const url = request.requestFormat(moxiApi.instance + `/${processId}/paybacks/`, params)
  return request({
    url,
    method: 'get',
    headers: {
      Authorization: id
    }
  })
}

export function moxiPaybackStatus(id, processId, data) {
  return request({
    url: moxiApi.instance + `/${processId}/enforce_complete_status/`,
    method: 'post',
    headers: {
      Authorization: id
    },
    data
  })
}

// 分单人列表
export function moxiDistributors(id, processId) {
  const url = `${moxiApi.instance}/${processId}/distributors/`
  return request({
    url,
    method: 'get',
    headers: {
      Authorization: id
    }
  })
}

// 审批实例内部表单
export function moxiSales(id, processId) {
  const url = `${moxiApi.form}/${processId}/`
  return request({
    url,
    method: 'get',
    headers: {
      Authorization: id
    }
  })
}

// 用户
export function moxiDingInfo(id) {
  const url = `${moxiApi.user}/`
  return request({
    url,
    method: 'get',
    headers: {
      Authorization: id
    }
  })
}

export function moxiRelation(wzgId, params) {
  const url = request.requestFormat(`${moxiApi.relation}/${wzgId}/`, params)
  return request({
    url,
    method: 'get'
  })
}

export function moxiGetRelation(id, params) {
  const url = request.requestFormat(`${moxiApi.get_relation}/`, params)
  return request({
    url,
    method: 'get',
    headers: {
      Authorization: id
    }
  })
}

export function moxiDepartments(id, params) {
  const url = request.requestFormat(`${moxiApi.department}/`, params)
  return request({
    url,
    method: 'get',
    headers: {
      Authorization: id
    }
  })
}

export function moxiDepartmentTree(id, depId) {
  const url = `${moxiApi.department}/${depId}/`
  return request({
    url,
    method: 'get',
    headers: {
      Authorization: id
    }
  })
}
