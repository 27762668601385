import request from '@/utils/request'

const ossApi = {
  // get oss info
  oss: '/api/v1/cloud/aliyun/oss/sign'
}

/**
 * func
 * @param 
 * @returns {*}
 */
export function sign () {
  return request({
    url: ossApi.oss,
    method: 'get'
  })
}

/**
 * type: form
 * @param { host: "https://ste-dev.oss-cn-chengdu.aliyuncs.com", 
 *          data: { 
 *            key: '', 
 *            OSSAccessKeyId: '', 
 *            policy: '', 
 *            Signature: '', 
 *            file: ''}  
 *        }
 * @returns {*}
 */
export function upload (host, parameters) {
  return request({
    url: host,
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data: parameters
  })
}
