// eslint-disable-next-line
import { UserLayout, BasicLayout, BlankLayout, PageView } from '@/layouts'
import { bxAnaalyse } from '@/core/icons'

const RouteView = {
  name: 'RouteView',
  render: (h) => h('router-view')
}

export const asyncRouterMap = [

  {
    path: '/',
    name: 'index',
    component: BasicLayout,
    meta: { title: '首页' },
    redirect: '/backstage/cockpit',
    children: [
      // 首页
      {
        path: '/backstage',
        name: 'backstage',
        redirect: '/backstage/cockpit:pageNo([1-9]\\d*)?',
        component: RouteView,
        meta: { title: '首页', keepAlive: true, icon: bxAnaalyse, permission: ['home'] },
        children: [
          {
            path: '/backstage/cockpit/:pageNo([1-9]\\d*)?',
            name: 'Cockpit',
            component: () => import('@/views/backstage/Cockpit'),
            meta: { title: '驾驶舱', keepAlive: false, permission: ['home_dashboard'] }
          }
        ]
      },
      // Exception
      {
        path: '/exception',
        name: 'exception',
        hidden: true,
        component: RouteView,
        redirect: '/exception/403',
        meta: { title: '异常页', icon: 'warning', permission: ['exception'] },
        children: [
          {
            path: '/exception/403',
            name: 'Exception403',
            component: () => import('@/views/exception/403'),
            meta: { title: '403', permission: ['exception'] }
          },
          {
            path: '/exception/404',
            name: 'Exception404',
            component: () => import('@/views/exception/404'),
            meta: { title: '404', permission: ['exception'] }
          },
          {
            path: '/exception/500',
            name: 'Exception500',
            component: () => import('@/views/exception/500'),
            meta: { title: '500', permission: ['exception'] }
          }
        ]
      },
      // residence
      {
        path: '/residence',
        name: 'residence',
        component: RouteView,
        redirect: '/residence/listView',
        meta: { title: '住宅', icon: 'bank', permission: ['residence'] },
        children: [
          {
            path: '/residence/listView',
            name: 'ResidenceListViewWrapper',
            hideChildrenInMenu: true,
            redirect: '/residence/list',
            component: RouteView,
            meta: { title: '楼盘列表', keepAlive: true, permission: ['residence_list'] },
            children: [
              {
                path: '/residence/list',
                name: 'ResidenceListWrapper',
                component: () => import('@/views/residence/housesList'),
                meta: { title: '列表信息', keepAlive: false, permission: ['residence_list'] }
              },
              {
                path: '/residence/houses',
                name: 'housesEdit',
                component: () => import('@/views/residence/houses/Index'),
                meta: { title: '编辑楼盘', hideHeader: true, permission: ['residence_edit'] },
                redirect: '/residence/houses/base',
                hideChildrenInMenu: true,
                children: [
                  {
                    path: '/residence/houses/base',
                    name: 'InfoEdit',
                    component: () => import('@/views/residence/houses/BaseSetting'),
                    meta: { title: '楼盘信息', hidden: true, permission: ['residence_edit_info'] }
                  },
                  {
                    path: '/residence/houses/img',
                    name: 'ImgEdit',
                    component: () => import('@/views/residence/houses/img'),
                    meta: { title: '图片视频', hidden: true, permission: ['residence_edit_media'] }
                  },
                  {
                    path: '/residence/houses/vr',
                    name: 'VREdit',
                    component: () => import('@/views/residence/houses/vr'),
                    meta: { title: 'VR看房', hidden: true, permission: ['residence_edit_vr'] }
                  },
                  {
                    path: '/residence/houses/presell',
                    name: 'Presell',
                    hideChildrenInMenu: true,
                    component: () => import('@/views/residence/houses/presell'),
                    meta: { title: '预售许可证', hidden: true, permission: ['residence_edit_license'] }
                  },
                  {
                    path: '/residence/houses/type',
                    name: 'TypeEdit',
                    component: () => import('@/views/residence/houses/type'),
                    meta: { title: '户型维护', hidden: true, permission: ['residence_edit_room'] }
                  },
                  {
                    path: '/residence/houses/map',
                    name: 'MapEdit',
                    component: () => import('@/views/residence/houses/map'),
                    meta: { title: '地理位置', hidden: true, permission: ['residence_edit_geo'] }
                  },
                  {
                    path: '/residence/houses/dynamic',
                    name: 'DynamicEdit',
                    component: () => import('@/views/residence/houses/dynamic'),
                    meta: { title: '楼盘动态', hidden: true, permission: ['residence_edit_news'] }
                  },
                  {
                    path: '/residence/houses/tag',
                    name: 'TagEdit',
                    component: () => import('@/views/residence/houses/tag'),
                    meta: { title: '标签', hidden: true, permission: ['residence_edit_tags'] }
                  },
                  {
                    path: '/residence/houses/agent',
                    name: 'AgentEdit',
                    component: () => import('@/views/residence/houses/agent'),
                    meta: { title: '代理商', hidden: true, permission: ['residence_edit_agent'] }
                  },
                  {
                    path: '/residence/houses/manager',
                    name: 'ManagerEdit',
                    component: () => import('@/views/residence/houses/manager'),
                    meta: { title: '掌柜', hidden: true, permission: ['residence_edit_site'] }
                  }
                ]
              }
            ]
          }
        ]
      },

      // task
      {
        path: '/task',
        name: 'task',
        component: RouteView,
        redirect: '/task/haveRelease',
        meta: { title: '任务管理', icon: 'audit', permission: ['task'] },
        children: [
          {
            path: '/task/haveRelease',
            name: 'TaskHaveReleaseViewWrapper',
            component: RouteView,
            redirect: '/task/haveRelease/index',
            hideChildrenInMenu: true,
            meta: { title: '已发布任务', keepAlive: true, permission: [ 'task_list' ] },
            children: [
              {
                path: '/task/haveRelease/index',
                name: 'TaskTaskHaveReleaseIndexWrapper',
                component: () => import('@/views/task/haveRelease/index'),
                meta: { title: '已发布任务', keepAlive: true, hiddenHeaderContent: true, permission: [ 'task_list' ] }
              },
              {
                path: '/task/haveRelease/detail',
                name: 'TaskTaskInfoWrapper',
                component: () => import('@/views/task/detail/index'),
                meta: { title: '任务详情', keepAlive: true, hiddenHeaderContent: true, permission: [ 'task_list' ] }
              }
            ]
          },
          {
            path: '/task/release/index',
            name: 'TaskReleaseWrapper',
            component: () => import('@/views/task/release/index'),
            meta: { title: '发布任务', keepAlive: true, permission: ['task_add'] }
          },
          {
            path: '/task/audit',
            name: 'TaskAuditWrapper',
            component: RouteView,
            redirect: '/task/audit/index',
            hideChildrenInMenu: true,
            meta: { title: '审核任务', keepAlive: true, show: false, permission: ['task_audit'] },
            children: [
              {
                path: '/task/audit/index',
                name: 'TaskkAuditIndexWrapper',
                component: () => import('@/views/task/audit/index'),
                meta: { title: '任务列表', keepAlive: true, hiddenHeaderContent: true, permission: ['task_audit'] }
              },
              {
                path: '/task/audit/approve',
                name: 'TaskApproveWrapper',
                component: () => import('@/views/task/audit/approve'),
                meta: { title: '任务审批单', keepAlive: true, hiddenHeaderContent: true, permission: ['task_audit'] }
              },
              {
                path: '/task/audit/detail',
                name: 'TaskAuditDetailWrapper',
                component: () => import('@/views/task/detail/index'),
                meta: { title: '任务详情', keepAlive: true, hiddenHeaderContent: true, permission: ['task_audit'] }
              }
            ]
          }
        ]
      },
      // consultant
      // {
      //   path: '/consultant',
      //   name: 'consultant',
      //   component: RouteView,
      //   redirect: '/consultant/list',
      //   meta: { title: '置业顾问', icon: 'solution', permission: [ 'order' ] },
      //   children: [
      //     {
      //       path: '/consultant/list',
      //       name: 'consultantListWrapper',
      //       hideChildrenInMenu: true,
      //       component: () => import('@/views/consultant/list'),
      //       meta: { title: '置业顾问列表', keepAlive: true }
      //     },
      //     {
      //       path: '/consultant/ranking/:pageNo([1-9]\\d*)?',
      //       name: 'ConsultantRankingWrapper',
      //       hideChildrenInMenu: true,
      //       component: () => import('@/views/consultant/ranking'),
      //       meta: { title: '数据排行', keepAlive: true, permission: [ 'order_report' ] }
      //     }
      //   ]
      // },
      // {
      //   path: '/risk',
      //   name: 'risk',
      //   component: RouteView,
      //   redirect: '/risk/house',
      //   meta: { title: '风控管理', icon: 'rise' },
      //   children: [
      //     {
      //       path: '/risk/house',
      //       name: 'riskHouseWrapper',
      //       hideChildrenInMenu: true,
      //       component: () => import('@/views/risk/house/index'),
      //       meta: { title: '图像证据', keepAlive: true }
      //     },
      //     {
      //       path: '/risk/sign',
      //       name: 'riskSignWrapper',
      //       hideChildrenInMenu: true,
      //       component: () => import('@/views/risk/sign/index'),
      //       meta: { title: '风控预警', keepAlive: true }
      //     }
      //   ]
      // },
      // order
      {
        path: '/order',
        name: 'order',
        component: RouteView,
        redirect: '/order/report',
        meta: { title: '客单管理', icon: 'calendar', permission: ['order'] },
        children: [
          {
            path: '/order/report/index',
            name: 'OrderReportWrapper',
            hideChildrenInMenu: true,
            component: () => import('@/views/order/report/index'),
            meta: { title: '报备单', keepAlive: true, permission: ['order_report'] }
          },
          {
            path: '/order/sign/index',
            name: 'OrderSignWrapper',
            hideChildrenInMenu: true,
            component: () => import('@/views/order/sign/index'),
            meta: { title: '签约单', keepAlive: true, permission: ['order_report'] }
          }
        ]
      },

      // developer
      {
        path: '/developer',
        name: 'developer',
        component: RouteView,
        redirect: '/developer/list/index',
        meta: { title: '公司管理', icon: 'deployment-unit', permission: ['developer'] },
        children: [
          {
            path: '/developer/list/index',
            name: 'DeveloperListWrapper',
            hideChildrenInMenu: true,
            component: () => import('@/views/developer/list/index'),
            meta: { title: '开发商', keepAlive: true, permission: ['developer_list'] }
          },
          {
            path: '/developer/agent/index',
            name: 'DeveloperAgentWrapper',
            hideChildrenInMenu: true,
            component: () => import('@/views/developer/agent/index'),
            meta: { title: '代理商', keepAlive: true, permission: ['developer_agent'] }
          },
          {
            path: '/developer/company/index',
            name: 'DeveloperCompanyWrapper',
            hideChildrenInMenu: true,
            component: () => import('@/views/developer/company/index'),
            meta: { title: '门店公司', keepAlive: true, permission: ['developer_company'] }
          },
          {
            path: '/developer/shop/index',
            name: 'DeveloperShopWrapper',
            hideChildrenInMenu: true,
            component: () => import('@/views/developer/shop/index'),
            meta: { title: '门店', keepAlive: true, permission: ['developer_shop'] }
          }
        ]
      },
      // client
      // {
      //   path: '/client',
      //   name: 'client',
      //   component: RouteView,
      //   redirect: '/client/recommend',
      //   meta: { title: '客户管理', icon: 'share-alt', permission: ['client'] },
      //   children: [
      //     {
      //       path: '/client/recommend/index',
      //       name: 'ClientManageWrapper',
      //       hideChildrenInMenu: true,
      //       component: () => import('@/views/client/recommend/index'),
      //       meta: { title: '老带新管理', keepAlive: true, permission: ['client_recommend'] }
      //     }
      //   ]
      // },
      // broker
      {
        path: '/broker',
        name: 'broker',
        component: RouteView,
        redirect: '/broker/list',
        meta: { title: '经纪人管理', icon: 'reddit', permission: ['broker'] },
        children: [
          {
            path: '/broker/list',
            name: 'BrokerListWrapper',
            hideChildrenInMenu: true,
            component: () => import('@/views/broker/list'),
            meta: { title: '经纪人', keepAlive: true, permission: ['broker_list'] }
          },
          {
            path: '/broker/invitation',
            name: 'BrokerInvitationWrapper',
            hideChildrenInMenu: true,
            component: () => import('@/views/broker/invitation'),
            meta: { title: '邀请码', keepAlive: true, permission: ['broker_invitation'] }
          }
        ]
      },
      // commission
      {
        path: '/commission',
        name: 'commission',
        component: RouteView,
        redirect: '/commission/publicity',
        meta: { title: '佣金管理', icon: 'account-book', permission: ['commission'] },
        children: [
          {
            path: '/commission/manage',
            name: 'CommissionManageWrapper',
            hideChildrenInMenu: true,
            component: () => import('@/views/commission/manage'),
            meta: { title: '结佣管理', keepAlive: true, permission: ['commission_audit'] }
          }
        ]
      },
      {
        path: '/member',
        name: 'member',
        component: RouteView,
        redirect: '/member/menu',
        meta: { title: '上数系统', icon: 'audit', permission: ['member'] },
        children: [
          {
            path: '/member/register',
            name: 'MemberRegisterWrapper',
            component: () => import('@/views/member/register/index'),
            meta: { title: '账号绑定', keepAlive: true, permission: ['member_register'] }
          },
          {
            path: '/member/mine',
            name: 'MemberMineWrapper',
            component: RouteView,
            hideChildrenInMenu: true,
            redirect: '/member/mine/index',
            meta: { title: '我的业绩', keepAlive: true, permission: ['member_mine'] },
            children: [
              {
                path: '/member/mine/index',
                name: 'MemberMineIndexWrapper',
                component: () => import('@/views/member/mine/index'),
                meta: { title: '我的业绩', keepAlive: true, permission: ['member_mine'] }
              },
              {
                path: '/member/mine/detail',
                name: 'MemberDetaileWrapper',
                component: () => import('@/views/member/detail/index'),
                meta: { title: '业绩详情', keepAlive: true, permission: ['member_mine'] }
              }
            ]
          },
          {
            path: '/member/department',
            name: 'MemberDepartmentWrapper',
            component: RouteView,
            hideChildrenInMenu: true,
            redirect: '/member/department/index',
            meta: { title: '部门业绩', keepAlive: true, permission: ['member_department'] },
            children: [
              {
                path: '/member/department/index',
                name: 'MemberDepartmentIndexWrapper',
                component: () => import('@/views/member/department/index'),
                meta: { title: '部门业绩', keepAlive: true, permission: ['member_department'] }
              },
              {
                path: '/member/department/detail',
                name: 'MemberDetaileWrapper',
                component: () => import('@/views/member/detail/index'),
                meta: { title: '业绩详情', keepAlive: true, permission: ['member_department'] }
              }
            ]
          },
          {
            path: '/member/invoice',
            name: 'MemberInvoiceWrapper',
            component: RouteView,
            hideChildrenInMenu: true,
            redirect: '/member/invoice/index',
            meta: { title: '申请开票', keepAlive: true, permission: ['member_invoice'] },
            children: [
              {
                path: '/member/invoice/index',
                name: 'MemberInvoiceIndexWrapper',
                component: () => import('@/views/member/invoice/index'),
                meta: { title: '申请开票', keepAlive: true, permission: ['member_invoice'] }
              },
              {
                path: '/member/invoice/detail',
                name: 'MemberDetaileWrapper',
                component: () => import('@/views/member/detail/index'),
                meta: { title: '业绩详情', keepAlive: true, permission: ['member_invoice'] }
              }
            ]
          },
          {
            path: '/member/finance',
            name: 'MemberFinanceWrapper',
            component: RouteView,
            hideChildrenInMenu: true,
            redirect: '/member/finance/index',
            meta: { title: '财务回款', keepAlive: true, permission: ['member_finance'] },
            children: [
              {
                path: '/member/finance/index',
                name: 'MemberFinanceIndexWrapper',
                component: () => import('@/views/member/finance/index'),
                meta: { title: '财务回款', keepAlive: true, permission: ['member_finance'] }
              },
              {
                path: '/member/finance/detail',
                name: 'MemberDetaileWrapper',
                component: () => import('@/views/member/detail/index'),
                meta: { title: '业绩详情', keepAlive: true, permission: ['member_finance'] }
              }
            ]
          },
          {
            path: '/member/dismantle',
            name: 'MemberDismantleWrapper',
            component: RouteView,
            hideChildrenInMenu: true,
            redirect: '/member/dismantle/index',
            meta: { title: '对外拆佣', keepAlive: true, permission: ['member_dismantle'] },
            children: [
              {
                path: '/member/dismantle/index',
                name: 'MemberDismantleIndexWrapper',
                component: () => import('@/views/member/dismantle/index'),
                meta: { title: '对外拆佣', keepAlive: true, permission: ['member_dismantle'] }
              },
              {
                path: '/member/dismantle/detail',
                name: 'MemberDetaileWrapper',
                component: () => import('@/views/member/detail/index'),
                meta: { title: '业绩详情', keepAlive: true, permission: ['member_dismantle'] }
              }
            ]
          },
          {
            path: '/member/commission',
            name: 'MemberCommissionWrapper',
            component: RouteView,
            hideChildrenInMenu: true,
            redirect: '/member/commission/index',
            meta: { title: '佣金结算', keepAlive: true, permission: ['member_commission'] },
            children: [
              {
                path: '/member/commission/index',
                name: 'MemberCommissionIndexWrapper',
                component: () => import('@/views/member/commission/index'),
                meta: { title: '佣金结算', keepAlive: true, permission: ['member_commission'] }
              },
              {
                path: '/member/commission/detail',
                name: 'MemberDetaileWrapper',
                component: () => import('@/views/member/detail/index'),
                meta: { title: '业绩详情', keepAlive: true, permission: ['member_commission'] }
              }
            ]
          }
          // {
          //   path: '/member/sum',
          //   name: 'MemberSumWrapper',
          //   component: RouteView,
          //   hideChildrenInMenu: true,
          //   redirect: '/member/sum/index',
          //   meta: { title: '上数汇总', keepAlive: true, permission: ['member_sum'] },
          //   children: [
          //     {
          //       path: '/member/sum/index',
          //       name: 'MemberSumIndexWrapper',
          //       component: () => import('@/views/member/sum/index'),
          //       meta: { title: '上数汇总', keepAlive: true, permission: ['member_sum'] }
          //     },
          //     {
          //       path: '/member/sum/detail',
          //       name: 'MemberSumDetailWrapper',
          //       component: () => import('@/views/member/sum/detail'),
          //       meta: { title: '业绩详情', keepAlive: true, permission: ['member_sum'] }
          //     }
          //   ]
          // }
        ]
      },

      // auth
      {
        path: '/authManage',
        name: 'auth',
        component: RouteView,
        redirect: '/authManage/user',
        meta: { title: '权限管理', icon: 'key', permission: ['auth'] },
        children: [
          {
            path: '/authManage/user',
            name: 'authManageUserWrapper',
            hideChildrenInMenu: true,
            component: () => import('@/views/authManage/user'),
            meta: { title: '后台用户', keepAlive: true, permission: ['auth_user'] }
          },
          {
            path: '/authManage/role',
            name: 'authManageRoleWrapper',
            hideChildrenInMenu: true,
            component: () => import('@/views/authManage/role'),
            meta: { title: '角色管理', keepAlive: true, permission: ['auth_role'] }
          },
          {
            path: '/authManage/auth',
            name: 'authManageAuthWrapper',
            hideChildrenInMenu: true,
            component: () => import('@/views/authManage/auth'),
            meta: { title: '权限设置', keepAlive: true, permission: ['auth_set'] }
          }
        ]
      },

      // system
      {
        path: '/system',
        name: 'system',
        component: RouteView,
        redirect: '/systemSetup/city',
        meta: { title: '系统设置', icon: 'setting', permission: ['system'] },
        children: [
          {
            path: '/systemSetup/city/:pageNo([1-9]\\d*)?',
            name: 'systemSetupCityWrapper',
            hideChildrenInMenu: true,
            component: () => import('@/views/systemSetup/city'),
            meta: { title: '开通城市', keepAlive: true, permission: ['system_city'] }
          },
          {
            path: '/systemSetup/agreement',
            name: 'systemSetupAgreementWrapper',
            hideChildrenInMenu: true,
            redirect: '/systemSetup/agreement/list',
            // component: () => import('@/views/systemSetup/agreement'),
            component: RouteView,
            meta: { title: '协议配置', keepAlive: true, show: false, permission: ['system_config'] },
            children: [
              {
                path: '/systemSetup/agreement/list',
                name: 'systemSetupAgreementWrapperList',
                component: () => import('@/views/systemSetup/agreement'),
                meta: { title: '协议列表', keepAlive: false, hiddenHeaderContent: true, permission: ['system_config'] }
              },
              {
                path: '/systemSetup/agreement/edit',
                name: 'systemSetupAgreementEditWrapper',
                component: () => import('@/views/systemSetup/agreementEdit'),
                meta: { title: '协议编辑', keepAlive: false, permission: ['system_config'] }
              }
            ]
          }
          // {
          //   path: '/systemSetup/agreementEdit',
          //   name: 'systemSetupAgreementEditWrapper',
          //   hideChildrenInMenu: true,
          //   component: () => import('@/views/systemSetup/agreementEdit'),
          //   meta: { title: '协议编辑', keepAlive: true, permission: [ 'system_config' ] }
          // }
        ]
      }

      // other
      /*
      {
        path: '/other',
        name: 'otherPage',
        component: PageView,
        meta: { title: '其他组件', icon: 'slack', permission: [ 'dashboard' ] },
        redirect: '/other/icon-selector',
        children: [
          {
            path: '/other/icon-selector',
            name: 'TestIconSelect',
            component: () => import('@/views/other/IconSelectorView'),
            meta: { title: 'IconSelector', icon: 'tool', keepAlive: true, permission: [ 'dashboard' ] }
          },
          {
            path: '/other/list',
            component: RouteView,
            meta: { title: '业务布局', icon: 'layout', permission: [ 'support' ] },
            redirect: '/other/list/tree-list',
            children: [
              {
                path: '/other/list/tree-list',
                name: 'TreeList',
                component: () => import('@/views/other/TreeList'),
                meta: { title: '树目录表格', keepAlive: true }
              },
              {
                path: '/other/list/edit-table',
                name: 'EditList',
                component: () => import('@/views/other/TableInnerEditList'),
                meta: { title: '内联编辑表格', keepAlive: true }
              },
              {
                path: '/other/list/user-list',
                name: 'UserList',
                component: () => import('@/views/other/UserList'),
                meta: { title: '用户列表', keepAlive: true }
              },
              {
                path: '/other/list/role-list',
                name: 'RoleList',
                component: () => import('@/views/other/RoleList'),
                meta: { title: '角色列表', keepAlive: true }
              },
              {
                path: '/other/list/system-role',
                name: 'SystemRole',
                component: () => import('@/views/role/RoleList'),
                meta: { title: '角色列表2', keepAlive: true }
              },
              {
                path: '/other/list/permission-list',
                name: 'PermissionList',
                component: () => import('@/views/other/PermissionList'),
                meta: { title: '权限列表', keepAlive: true }
              }
            ]
          }
        ]
      }
      */
    ]
  },
  {
    path: '*', redirect: '/404', hidden: true
  }
]

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/user',
    component: UserLayout,
    redirect: '/user/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import('@/views/user/Login')
      },
      {
        path: 'register',
        name: 'register',
        component: () => import('@/views/user/Register')
      },
      {
        path: 'register-result',
        name: 'registerResult',
        component: () => import('@/views/user/RegisterResult')
      },
      {
        path: 'recover',
        name: 'recover',
        component: undefined
      }
    ]
  },

  {
    path: '/403',
    component: () => import('@/views/exception/403')
  },
  {
    path: '/404',
    component: () => import('@/views/exception/404')
  },
  {
    path: '/500',
    component: () => import('@/views/exception/500')
  }
]
