/* eslint-disable */
import store from '@/store'
import storage from 'store'
import { APP_SERIAL } from '@/store/mutation-types'
export const printSerial = () => {
    switch (process.env.NODE_ENV) {
        case 'development':
            storage.set(APP_SERIAL, 'http://11.0.2.101:8911')
            store.commit('SET_SERIAL', 'http://11.0.2.101:8911')
            break;
        case 'production':
            storage.set(APP_SERIAL, 'http://wzg.jinlutech.cn')
            store.commit('SET_SERIAL', 'http://wzg.jinlutech.cn')
            break;
        default:
            storage.set(APP_SERIAL, 'http://wzg-test.jinlutech.cn')
            store.commit('SET_SERIAL', 'http://wzg-test.jinlutech.cn')
            break
    }
}
