<template>
  <a-modal :title="noticeTitle" :visible="visible" :confirmLoading="loading" @ok="handleOk" @cancel="handleCancel">
    <template slot="footer">
      <a-button key="back" @click="handleCancel">取消</a-button>
      <a-button key="submit" type="primary" :loading="loading" @click="handleOk">确认</a-button>
    </template>
    <a-form :form="form">
      <a-form-item label="原来的密码" has-feedback>
        <a-input-password
          v-decorator="[
            'originalPassword',
            {
              rules: [
                {
                  required: true,
                  message: '请输入你的密码!'
                }
              ]
            }
          ]"
          type="password"
        />
      </a-form-item>
      <a-form-item label="新密码" has-feedback>
        <a-input-password
          v-decorator="[
            'newPassword',
            {
              rules: [
                {
                  required: true,
                  message: '请输入你的新密码!'
                },
                {
                  validator: validateToNextPassword
                }
              ]
            }
          ]"
          type="password"
        />
      </a-form-item>
      <a-form-item label="确认密码" has-feedback>
        <a-input-password
          v-decorator="[
            'confirmPassword',
            {
              rules: [
                {
                  required: true,
                  message: '请确认你的密码!'
                },
                {
                  validator: compareToFirstPassword
                }
              ]
            }
          ]"
          type="password"
          @blur="handleConfirmBlur"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { updatePassword } from '@/api/users'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import storage from 'store'
export default {
  name: 'UpdatePassword',
  data() {
    return {
      noticeTitle: '修改密码',
      form: this.$form.createForm(this),
      loading: false,
      visible: false
    }
  },
  methods: {
    showModal() {
      this.visible = true
    },
    handleOk() {
      this.loading = true
      this.form.validateFields((errors, values) => {
        if (!errors) {
          const param = { ...values }
          if (this.visible) {
            updatePassword(param)
              .then(res => {
                this.loading = false
                this.visible = false
                this.$notification.success({
                  message: this.noticeTitle,
                  description: '修改成功，将在两秒后重新登录'
                })
                setTimeout(() => {
                  storage.remove(ACCESS_TOKEN)
                  this.$router.push('/user/login')
                }, 2000)
              })
              .catch(() => {
                this.loading = false
              })
          } else {
            this.visible = false
          }
        } else {
          this.loading = false
        }
      })
    },
    handleCancel() {
      this.loading = false
      this.visible = false
    },
    handleConfirmBlur(e) {
      const value = e.target.value
      this.confirmDirty = this.confirmDirty || !!value
    },
    compareToFirstPassword(rule, value, callback) {
      const form = this.form
      if (value && value !== form.getFieldValue('newPassword')) {
        callback(new Error('两次输入的密码不一致!'))
      } else {
        callback()
      }
    },
    validateToNextPassword(rule, value, callback) {
      const form = this.form
      if (value && this.confirmDirty) {
        form.validateFields(['confirm'], { force: true })
      }
      callback()
    }
  }
}
</script>
