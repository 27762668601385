import pick from 'lodash.pick'
import moment from 'moment'
import {
    priceFormat,
    preNumberFormat,
    sufNumberFormat,
    dateFormat,
    phoneFormat
} from '@/utils/util'
import dutil from '@/utils/dutil'
const methods = {
    moment,
    pick,
    priceFormat,
    preNumberFormat,
    sufNumberFormat,
    dateFormat,
    phoneFormat,

    loadMixin() {
        console.log('mixins');
    },
    navTo(path, query) {
        this.$router.push({
            path,
            query
        })
    },
    navBack() {
        this.$router.go(-1)
    },
    newTarget(path, query) {
        const { href } = this.$router.resolve({
            path,
            query
          })
        window.open(href, '_blank')
    },
    to500() {
        this.$router.push('/500')
    },
    tableHome() {
        this.$refs.table.localPagination.current = 0
    },
    formValidator(fields) {
        // 防止表单未注册
        fields.forEach(v => this.form.getFieldDecorator(v))
        // 当 model 发生改变时，为表单设置值
        this.$watch('model', () => {
            this.model && this.form.setFieldsValue(this.pick(this.model, fields))
        })
    },
    btnPermission(val) {
        const roles = this.userStore.roles[0].permissionList
        return roles.includes(val)
    },
    requestFormat(url, param) {
        if (param) {
            url += '?' + Object.keys(param).map(item => `${item}=${param[item]}`).join('&')
        }
        return url
    },
    paramFormat(parameter, param = {}, type = 'createTime') {
        let sort = ''
        const pagination = {
            page: parameter.pageNo - 1,
            size: parameter.pageSize
        }
        if (type) {
            if (parameter.sortOrder === 'descend') {
                sort = `${parameter.sortField},desc`
            } else if (parameter.sortOrder === 'ascend') {
                sort = `${parameter.sortField}`
            } else {
                sort = `${type},desc`
            }
        }
        Object.assign(param, pagination)
        if (type) Object.assign(param, { sort })
        console.log(param, 'sparam');
        return param
    },
    resFormat(res, type = '') {
        const data = res.data
        const result = {
            data: data.content,
            pageNo: data.number,
            pageSize: data.size,
            totalCount: data.totalElements,
            totalPages: data.totalPages
        }
        switch (type) {
            case 'area':
                result.data.forEach(item => {
                    item.area = []
                    item.area.push(item.province)
                    item.area.push('-' + item.city)
                    item.area.push('-' + item.district)
                    item.area.push('-' + item.street)
                })
                break
        }
        console.log(result, 's result');
        return result
    },
    userRole() {
        const roleArr = this.$store.state.user.roles
        const roleIdArr = roleArr.map(item => item.id)
        let flag = false
        if (roleIdArr.includes('resident')) {
            flag = true
        }
        if (roleIdArr.includes('admin')) {
            flag = false
        }
        return flag
    },
    notiSuccess(title, description) {
        this.$notification.success({
            message: title,
            description
        })
    },
    notiError(title, description) {
        this.$notification.error({
            message: title,
            description
        })
    },
    notiInfo(title, description) {
        this.$notification.info({
            message: title,
            description
        })
    }
}
Object.assign(methods, dutil)
export default {
    data() {
        return {
            // media
            THUMBNAIL: '?x-oss-process=image/resize,w_250',
            THUMBNAIL_MAX: '?x-oss-process=image/resize,w_500',
            THUMBNAIL_ORIGIN: '?x-oss-process=image/resize,w_2560',
            THUMBNAIL_VEDIOCOVER: '?x-oss-process=video/snapshot,t_0,f_jpg,w_800,h_600,m_fast',
            VUE_APP_MAP_TILELAYER_URL: 'https://webrd0{s}.is.autonavi.com/appmaptile?lang=zh_cn&size=1&scale=1&style=8&x={x}&y={y}&z={z}&mapType=normal', // GMap 服务器地址&配置
            init: 'mixin-loading',
            // input
            scrollHeight: '600px',
            limitLength: 30,
            areaLimitLength: 1000,
            areaLimitRows: 10,
            areaLimitText: `长度不能大于1000`,
            defaultCityValue: ['重庆市', '市辖区', '北碚区', '朝阳街道'],
            formLayout: {
                labelCol: {
                    xs: { span: 24 },
                    sm: { span: 7 }
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 13 }
                }
            },
            cityField: { label: 'name', value: 'name', children: 'children' },
            // drawer
            drawerWidth: '600px',
            // modal
            modalWidth: '640px',
            // s-table
            defaultScroll: { x: 1500, y: 600 },
            maxPagination: {
                page: 0,
                size: 999,
                sort: 'createTime,desc'
            },
            defaultPagination: {
                page: 0,
                size: 10,
                sort: 'createTime,desc'
            },
            defaultCityOptions: [
                {
                    name: '重庆市',
                    children: [
                        {
                            name: '市辖区',
                            children: [
                                {
                                    name: '北碚区',
                                    children: [
                                        {
                                            name: '朝阳街道'
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ],
            asyncTime: 1000 // 异步请求时间
        }
    },
    computed: {
        userStore() {
            return this.$store.state.user
        }
    },
    filters: {
        textFilter(type) {
            const validator = type !== undefined && type !== 'null' && type
            if (validator) {
                return type
            } else {
                return '--'
            }
        },
        numberFilter(type) {
            if (type) {
                return `${priceFormat(type)}`
            } else {
                if (type === 0) {
                    return type
                } else {
                    return '--'
                }
            }
        },
        phoneFilter(type) {
            const validator = type !== undefined && type !== 'null' && type
            if (validator) {
                return `${phoneFormat(type)}`
            } else {
                return '--'
            }
        },
        priceFilter(type) {
            const validator = type !== undefined && type !== 'null' && type
            if (validator) {
                return `￥${priceFormat(type)}`
            } else {
                if (type === 0) {
                    return `￥${type}`
                } else {
                    return '--'
                }
            }
        },
        moneyFilter(type) {
            const validator = type !== undefined && type !== 'null' && type
            if (validator) {
                return `${priceFormat(type)} 元`
            } else {
                if (type === 0) {
                    return `${type} 元`
                } else {
                    return '--'
                }
            }
        },
        areaFilter(type) {
            if (type) {
                return `${priceFormat(type)} ㎡`
            } else {
                if (type === 0) {
                    return `${type} ㎡`
                } else {
                    return '--'
                }
            }
        },
        setFilter(type) {
            if (type) {
                return `${priceFormat(type)} 套`
            } else {
                if (type === 0) {
                    return `${type} 套`
                } else {
                    return '--'
                }
            }
        }
    },
    methods
}
