import { moxiRelation } from '@/api/moxi'
const ding = {
    state: {
        data: {}
    },
    mutations: {
        SET_DINGUSER (state, data) {
            state.data = data
        }
    },
    actions: {
        DingLogin({ commit }, userId) {
            return new Promise((resolve, reject) => {
                moxiRelation(userId).then(res => {
                    if (res.success) {
                        const data = res.data
                        commit('SET_DINGUSER', data)
                        resolve(res)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        }
    }
}
export default ding
